import React, { useContext, lazy } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Navigate } from "react-router-dom";
import MainLayout from "../layouts/Main";
import Loadable from "../components/loadable/Loadable";
import { AuthContext } from "../App";
import Notification from "../modules/notification/Notification";
import Monitor from "../modules/monitor/Monitor";

const Dashboard = Loadable(lazy(() => import("../modules/dashboard/Dashboard")));

const Product = Loadable(lazy(() => import("../modules/product/Product")));
const NewProduct = Loadable(lazy(() => import("../modules/product/NewProduct")));
const ProductDetail = Loadable(lazy(() => import("../modules/product/ProductDetail")));

const Order = Loadable(lazy(() => import("../modules/order/Order")));
const NewOrder = Loadable(lazy(() => import("../modules/order/NewOrder")));
const OrderDetail = Loadable(lazy(() => import("../modules/order/OrderDetail")));

const Production = Loadable(lazy(() => import("../modules/production/Production")));
const ProductionDetail = Loadable(lazy(() => import("../modules/production/ProductionDetail")));

const QRDesign = Loadable(lazy(() => import("../modules/qrDesign/QRDesign")));
const NewQRDesign = Loadable(lazy(() => import("../modules/qrDesign/NewQRDesign")));
const QRDesignEditor = Loadable(lazy(() => import("../modules/qrDesign/QRDesignEditor")));

const Template = Loadable(lazy(() => import("../modules/template/Template")));
const TemplateEditor = Loadable(lazy(() => import("../modules/template/TemplateEditor")));

const Profile = Loadable(lazy(() => import("../modules/profile/Profile")));

// // Sinisana
// const Security = Loadable(lazy(() => import("../views/sinisana/security/Security")));
// const SecurityDetail = Loadable(lazy(() => import("../views/sinisana/security/SecurityDetail")));
const Entity = Loadable(lazy(() => import("../modules/entity/Entity")));
const EntityDetail = Loadable(lazy(() => import("../modules/entity/EntityDetail")));
const User = Loadable(lazy(() => import("../modules/user/User")));
const UserDetail = Loadable(lazy(() => import("../modules/user/UserDetail")));

const PermissionDenied = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <h1>Permission Denied</h1>
          <p>You don't have permission to access this page. Please contact your administrator.</p>
        </Box>
      </Grid>
    </Grid>
  );
};

const ProtectedRoute = ({ children }) => {
  const { authState } = useContext(AuthContext);

  if (!authState.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

// ==============================|| MAIN ROUTING ||============================== //
const AdminRoutes = (can) => {
  return {
    path: "/admin",
    element: (
      <ProtectedRoute>
        <MainLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "monitor",
        element: can("Monitor") ? <Monitor /> : <PermissionDenied />,
      },
      {
        path: "dashboard",
        element: can("Dashboard") ? <Dashboard /> : <PermissionDenied />,
      },
      {
        path: "products",
        element: can("Product.View") ? <Product /> : <PermissionDenied />,
      },
      {
        path: "products/create",
        element: can("Product.Create") ? <NewProduct /> : <PermissionDenied />,
      },
      {
        path: "products/:id",
        element: can("Product.View") ? <ProductDetail /> : <PermissionDenied />,
      },
      {
        path: "orders",
        element: can("Order.View") ? <Order /> : <PermissionDenied />,
      },
      {
        path: "orders/create",
        element: can("Order.Create") ? <NewOrder /> : <PermissionDenied />,
      },
      {
        path: "orders/:id",
        element: can("Order.View") ? <OrderDetail /> : <PermissionDenied />,
      },
      {
        path: "productions",
        element: can("Production.View") ? <Production /> : <PermissionDenied />,
      },
      {
        path: "productions/:id",
        element: can("Production.View") ? <ProductionDetail /> : <PermissionDenied />,
      },
      {
        path: "qrDesigns",
        element: can("QRDesign.View") ? <QRDesign /> : <PermissionDenied />,
      },
      {
        path: "qrDesigns/create",
        element: can("QRDesign.Create") ? <NewQRDesign /> : <PermissionDenied />,
      },
      {
        path: "qrDesigns/:id/editor",
        element: can("QRDesign.Create") ? <QRDesignEditor /> : <PermissionDenied />,
      },
      {
        path: "templates",
        element: can("Template.View") ? <Template /> : <PermissionDenied />,
      },
      {
        path: "templates/:id/editor",
        element: can("Template.Create") ? <TemplateEditor /> : <PermissionDenied />,
      },
      {
        path: "users",
        element: can("User") ? <User /> : <PermissionDenied />,
      },
      {
        path: "users/:id",
        element: can("User") ? <UserDetail /> : <PermissionDenied />,
      },
      {
        path: "entities",
        element: can("Entity") ? <Entity /> : <PermissionDenied />,
      },
      {
        path: "entities/:id",
        element: can("Entity") ? <EntityDetail /> : <PermissionDenied />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "notifications",
        element: <Notification />,
      },
    ],
  };
};

export default AdminRoutes;
