import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import DesignServicesIcon from "@mui/icons-material/DesignServices";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FeedIcon from "@mui/icons-material/Feed";

import PeopleIcon from "@mui/icons-material/People";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StoreIcon from "@mui/icons-material/Store";

import { SidebarNav } from "./components";

const drawerWidth = 240;

const Sidebar = (props) => {
  const { open, variant, onClose, onOpen } = props;

  const theme = useTheme();

  const pages = [
    {
      title: "Monitor",
      href: "/admin/monitor",
      icon: <DashboardIcon />,
      permission: "Monitor",
    },
    {
      title: "Dashboard",
      href: "/admin/dashboard",
      icon: <DashboardIcon />,
      permission: "Dashboard",
    },
    {
      title: "Product",
      href: "/admin/products",
      icon: <StoreIcon />,
      permission: "Product.View",
    },
    {
      title: "Order",
      href: "/admin/orders",
      icon: <ShoppingCartIcon />,
      permission: "Order.View",
    },
    {
      title: "Production",
      href: "/admin/productions",
      icon: <PlaylistAddCheckIcon />,
      permission: "Production.View",
    },
    {
      title: "QR Design",
      href: "/admin/qrDesigns",
      icon: <DesignServicesIcon />,
      permission: "QRDesign.View",
    },
    {
      title: "Template",
      href: "/admin/templates",
      icon: <FeedIcon />,
      permission: "Template.View",
    },
    {
      title: "User",
      href: "/admin/users",
      icon: <PeopleIcon />,
      permission: "User",
    },
    {
      title: "Entity",
      href: "/admin/entities",
      icon: <StoreIcon />,
      permission: "Entity",
    },
  ];

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      variant={variant}
      PaperProps={{
        sx: {
          marginTop: "64px",
          height: "calc(100vh - 64px)",
          ...(open && {
            width: drawerWidth,
            whiteSpace: "nowrap",
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
          ...(!open && {
            width: 70,
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }),
        },
      }}
    >
      <div
        style={{
          // backgroundColor: "skyblue",
          // backgroundColor: theme.palette.white,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          // padding: theme.spacing(2),
          padding: `${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(8)} ${theme.spacing(2)}`,
        }}
      >
        <SidebarNav pages={pages} open={open} />

        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "calc(100% - 30px)",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "0",
              margin: "0",
              ...theme.mixins.toolbar,
            }}
          >
            {open ? (
              <IconButton onClick={onClose}>
                <ChevronLeftIcon />
              </IconButton>
            ) : (
              <IconButton onClick={onOpen}>
                <ChevronRightIcon />
              </IconButton>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
